/* RegistroInicial.css */

/* src/styles/RegistroInicial.css */
.registro-inicial {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding-top: 5px; /* Añade separación del header */
}

.formulario {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px; /* Reduce el tamaño del formulario */
  max-width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

button {
  width: 48%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

button[type="submit"] {
  background-color: #007bff;
  color: white;
  margin-right: 4%; /* Espacio entre botones */
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

button[type="button"] {
  background-color: #6c757d;
  color: white;
}

button[type="button"]:hover {
  background-color: #5a6268;
}

.error {
  color: red;
  font-size: 12px;
}

h1 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.alert-top {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
}

.alert.success {
  background-color: #d4edda;
  color: #155724;
}

.alert.error {
  background-color: #f8d7da;
  color: #721c24;
}

.alert.warning {
  background-color: #fff3cd;
  color: #856404;
}

.alert.info {
  background-color: #d1ecf1;
  color: #0c5460;
}

.alert.link-warning a {
  color: #856404;
  text-decoration: underline;
}

.error {
  color: red;
  font-size: 14px;
}
