.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Fondo semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegura que esté por encima de otros elementos */
  }
  
  .loader-content {
    text-align: center;
  }
  
  .loader-content img {
    width: 100px; /* Ajusta el tamaño según necesites */
  }
  