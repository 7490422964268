.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f7f7f7;
  }
  
  .login-card {
    width: 400px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #ffffff;
  }
  
  .login-card .form-label {
    font-weight: bold;
  }
  
  .login-card .btn-primary {
    background: linear-gradient(45deg, #007bff, #0056b3);
    border: none;
  }
  
  .login-card .btn-primary:hover {
    background: linear-gradient(45deg, #0056b3, #007bff);
  }
  
  .login-card .input-group-text {
    cursor: pointer;
  }