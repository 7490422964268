/* Marquee.css */

.marquee-container {
  position: fixed;
  bottom: 0; /* Mantiene la marquesina en la parte inferior */
  left: 0;
  width: 100%;
  background-color: #007bff; /* Color de fondo del mensaje */
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: 18px;
  z-index: 999; /* Asegura que el mensaje esté por encima de otros elementos */
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 10s linear infinite; /* Ajustar la duración para un desplazamiento continuo */
}

@keyframes marquee {
  from {
      transform: translateX(100%); /* Inicia desde la derecha */
  }
  to {
      transform: translateX(-100%); /* Termina en la izquierda */
  }
}
