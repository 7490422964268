.verificar-correo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh; /* Ocupa toda la altura de la pantalla */
    background-color: #f2efef; /* Color de fondo claro */
    text-align: center;
    padding: 1px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.verificar-correo h1 {
    font-size: 2rem; /* Tamaño de la fuente para el título */
    color: #4CAF50; /* Color verde para el texto */
    margin-bottom: 15px;
}

.verificar-correo p {
    font-size: 1.2rem; /* Tamaño de la fuente para el párrafo */
    color: #555; /* Color gris oscuro para el texto */
    margin-bottom: 30px;
}

.verificar-correo button {
    padding: 10px 20px;
    font-size: 1rem; /* Tamaño de la fuente del botón */
    color: white; /* Color del texto del botón */
    background-color: #007BFF; /* Color azul del botón */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Efecto de transición */
}

.verificar-correo button:hover {
    background-color: #0056b3; /* Color al pasar el ratón por encima */
}
