/* CSS Global para las páginas de contenido */

.supervision-page {
    padding: 10px ;
    background-color: #f8f9fa; /* Fondo claro para contrastar con el texto */
  }
  
  .supervision-page h1 {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .section {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .image-container {
    flex: 1;
    margin: 0 20px;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
  }
  
  .text-container {
    flex: 2;
    text-align: justify;
  }
  
  .text-container h2 {
    margin-top: 0;
  }
  
  .left .image-container {
    order: 1;
  }
  
  .right .image-container {
    order: 2;
  }
  
  @media (max-width: 768px) {
    .section {
      flex-direction: column;
      text-align: center;
    }
  
    .image-container {
      margin: 0 0 20px 0;
    }
  
    .left .image-container, .right .image-container {
      order: initial;
    }
  }
  