.maintenance-page {
    font-family: Arial, sans-serif;
    padding: 1px;
    background: #f4f4f4; /* Fondo claro para la página */
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    background: #fff; /* Fondo blanco para las secciones */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .image-container {
    flex: 1;
    padding: 10px;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .text-container {
    flex: 2;
    padding: 10px;
  }
  
  .text-container h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .text-container p {
    text-align: justify;
  }
  
  .left {
    order: -1;
  }
  
  .right {
    order: 1;
  }
  
  @media (max-width: 768px) {
    .section {
      flex-direction: column;
      text-align: center;
    }
    
    .image-container, .text-container {
      flex: none;
      width: 100%;
    }
  }
  