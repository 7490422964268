.calibration-page {
  padding: 10px;
}

.section {
  display: flex;
  align-items: flex-start; /* Alinea el contenido al inicio del contenedor */
  margin-bottom: 3px;
  gap: 20px; /* Espacio entre la imagen y el texto */
}

.section.left {
  flex-direction: row;
}

.section.right {
  flex-direction: row-reverse;
}

.image-container {
  flex: 0 0 30%; /* Ajusta el tamaño de la imagen */
  max-width: 30%;
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.text-container {
  flex: 1; /* Permite que el texto ocupe el espacio restante */
  text-align: justify; /* Justifica el texto */
}

h1, h2 {
  color: #333;
}

p {
  line-height: 1.6;
  color: #555;
  margin: 0; /* Elimina márgenes por defecto para mejor alineación */
}

@media (max-width: 768px) {
  .section {
    flex-direction: column;
    text-align: center;
  }

  .image-container,
  .text-container {
    max-width: 100%;
    padding: 0;
  }

  .image-container {
    margin-bottom: 20px;
  }
}
