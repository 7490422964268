.header {
  position: fixed; /* Fija el encabezado en la parte superior */
  top: 0;
  left: 0;
  width: 100%;
  height: 80px; /* Altura del encabezado */
  background: linear-gradient(to right, #adaaa9, #c5c3c3); /* Fondo degradado */
  color: white;
  z-index: 1000; /* Asegura que el encabezado esté por encima de otros elementos */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Sombra para darle profundidad */
  display: flex;
  align-items: center; /* Centra el contenido verticalmente */
  padding: 0 20px; /* Añade padding a los lados */
}

/* Estilos para la navegación */
.header .navbar-nav {
  display: flex;
  align-items: center; /* Centra los elementos de la navbar */
}

/* Estilo de los botones en la navbar */
.header .navbar-nav .btn {
  flex: 1; /* Permite que los botones ocupen el mismo espacio */
  margin-left: 10px; /* Espacio entre los botones */
  padding: 8px 0; /* Ajusta el padding de los botones */
  font-size: 0.875rem; /* Tamaño de fuente más pequeño */
  border-radius: 8px; /* Bordes redondeados más marcados */
  background-color: #6c757d; /* Color de fondo de los botones */
  color: white; /* Color del texto */
  text-align: center; /* Centra el texto en el botón */
  transition: background-color 0.3s, transform 0.2s; /* Transición suave para el hover */
  white-space: nowrap; /* Asegura que el texto no se divida en varias líneas */
}

/* Color de fondo al pasar el ratón */
.header .navbar-nav .btn:hover {
  background-color: #5a6268; /* Color de fondo al pasar el ratón */
  transform: scale(1.05); /* Efecto de aumento sutil al pasar el ratón */
}

/* Media queries para responsividad */
@media (max-width: 768px) {
  .header {
    flex-direction: column; /* Cambia la dirección a columna en móviles */
    height: auto; /* Permite que la altura se ajuste según el contenido */
  }

  .header .navbar-nav {
    flex-direction: column; /* Alinea los elementos verticalmente */
    width: 100%; /* Asegura que ocupen el ancho completo */
    align-items: flex-end; /* Alinea los elementos a la derecha */
  }

  .header .navbar-nav .btn {
    margin-left: 0; /* Elimina el margen a la izquierda en móviles */
    width: 100%; /* Botones ocupan todo el ancho */
    font-size: 0.8rem; /* Tamaño de fuente más pequeño para móviles */
    padding: 8px 0; /* Padding ajustado para móviles */
  }
}

.logo {
  width: 100%;
  height: auto;
  max-width: 150px; /* Ajusta este valor según sea necesario */
}

.logo-large {
  width: 350px;
  height: auto;
}

.btn-modern {
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-modern:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* New CSS class for logout label */
.btn-logout {
  cursor: pointer; /* Change cursor to pointer when hovering */
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #dc3545;
  color: white;
  border-radius: 0.25rem;
  transition: background-color 0.3s;
}

.btn-logout:hover {
  background-color: #c82333;
}