.verificacion-exitosa {
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px; /* Limitar el ancho máximo */
    padding: 3px;
}

.titulo-exitoso {
    color: #28a745;
}

.mensaje-exitoso {
    font-size: 1.2rem;
    color: #6c757d;
}

.mensaje-instrucciones {
    font-size: 1rem;
    color: #6c757d;
}
