body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, #root, .App {
  height: 100%;
  margin: 0;
}

header {
  height: 20vh; /* 20% del alto de la ventana */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bg-gradient {
  background: linear-gradient(to right, violet, purple);
}

.nav-link {
  color: white !important;
}

.nav-link:hover {
  color: lightgray !important;
}

.container-fluid {
  padding: 0;
}
