/* Estilo general para el menú */
.menu {
  position: fixed; /* Fija el menú en la parte superior de la página */
  top: 80px; /* Ajusta este valor según la altura del encabezado */
  left: 0;
  width: 100%;
  background-color: #000000; /* Fondo negro para el menú */
  z-index: 900; /* Asegura que el menú esté por encima de otros elementos */
  display: flex; /* Usa flexbox para alinear los elementos del menú */
  justify-content: center; /* Centra los elementos horizontalmente */
  align-items: center; /* Centra los elementos verticalmente */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Sombra para darle profundidad */
  border-bottom: 2px solid #1a1a1a; /* Agrega un borde inferior para separar visualmente el menú */
  padding: 10px 0; /* Espaciado interno para el menú */
}

/* Estilo para los enlaces del menú */
.menu .nav-link {
  color: #ffffff; /* Color del texto de los enlaces */
  margin: 0 15px; /* Espaciado entre los elementos del menú */
  font-size: 0.9rem; /* Tamaño de fuente */
  font-weight: bold; /* Texto más destacado */
  text-transform: uppercase; /* Convierte el texto en mayúsculas */
  text-decoration: none; /* Elimina el subrayado del enlace */
  transition: color 0.3s, transform 0.3s; /* Transiciones suaves para color y transformación */
}

/* Efecto hover para los enlaces */
.menu .nav-link:hover {
  color: #ffcc00; /* Color del texto al pasar el mouse */
  transform: scale(1.1); /* Efecto de agrandamiento en hover */
}

/* Media Queries para responsividad */
@media (max-width: 768px) {
  /* Estilo para pantallas pequeñas */
  .menu {
    flex-direction: column; /* Coloca los elementos verticalmente */
    align-items: flex-start; /* Alinea los enlaces a la izquierda */
    padding-left: 20px; /* Margen izquierdo para separarlo del borde */
    height: auto; /* Ajusta la altura según el contenido */
    top: 60px; /* Ajusta la distancia del encabezado para pantallas pequeñas */
  }

  .menu .nav-link {
    margin: 10px 0; /* Espaciado entre los enlaces en pantallas pequeñas */
    font-size: 0.8rem; /* Tamaño de fuente más pequeño en pantallas pequeñas */
  }
}

/* Estilo para la franja blanca debajo del menú */
.franja-blanca {
  background-color: white; /* Color de la franja blanca */
  height: 5px; /* Altura de la franja */
  width: 100%; /* Ancho completo */
  position: absolute; /* Para posicionarlo justo debajo del menú */
  bottom: -5px; /* Ajusta según la altura de la franja */
  left: 0;
}
